html, body, #root {
  height: 100%;
}
main {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}
.chip-participant {
  position: absolute;
  top: 8px;
  right: 8px;
}
/* Start text styles */
.text-center {
  text-align: center;
}
/* End text styles  */
/* Start color styles */
.bg-ukraine-blue {
  background: #005bbb !important;
}
.bg-ukraine-yellow {
  background: #ffd500 !important;
}
.text-deloitte-green {
  color: #86bc25 !important;
}
.text-ukraine-blue {
  color: #005bbb !important;
}
.text-ukraine-yellow {
  color: #ffd500 !important;
}
/* End color styles */
.logo {
  max-width: 90px;
  height: auto;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #3a3a3e;
}
/* Start homepage hero */
@keyframes expand {
  0% {
    height: 0;
  }
  100% {
    height: 250px;
  }
}

#hero-homepage {
  background-position: center;
  background-size: 1152px;
  background-repeat: no-repeat;
  height: 250px;
  display: flex;
  align-items: center;
  z-index: -1;
  animation: 0.75s ease-in-out 0s 1 expand;
  transition: height 0.75s ease-in-out;
}

#hero-homepage.collapse {
  height: 0;
  transition: height 0.75s ease-in-out;
  overflow: hidden;
}

#hero-homepage h2, #hero-homepage h3 {
  padding: 8px 12px;
  line-height: 1.2;
  width: -moz-fit-content;
  width: fit-content;
  display: table; /* workaround for IE11 */
  font-weight: bold;
  background: rgba(255,255,255,0.75);
}
#hero-homepage h3 {
  text-transform: uppercase;
  background: #000;
  color: #fff;
}
.fade-in {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.75s linear;
}
.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.75s, opacity 0.75s linear;
}
/* End homepage hero */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fireworks-container {
  position: fixed;
  width: 100%;
  height: 90vh;
  top: 0;
  left: 0;
}
.separator {
  height: 4px;
  background: #91B942;
}

/* body styles start here */
footer {
  background: #18181b;
  margin-top: auto;
}

.label {
  border-radius: 8px;
  height: 24px;
  margin-bottom: 12px!important;
}

.label-icon {
  height: 20px;
  width: 20px;
  padding-left: 3px;
}
/* End body styles */

/* Start weekly container */
.weekly-chart-container {
  height: 225px;
}
/* End weekly container */

/* Start of styling for Authorize Strava page */
 .authorize-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  color: white; 
  background: url(https://images.unsplash.com/photo-1449358070958-884ac9579399?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80) no-repeat center 20% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #2E2E2E;
  font-family: "Open Sans", sans-serif;
}

 .authorize-container .authorize-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px;
}

.container-global-dashboard {
  height: calc( 100vw * 0.6);
}

/* #GoogleFormDialogOpenBtn {
  border: none;
  padding-left: 0;
  background: none;
  padding-bottom: 0;
  color:black;
  text-transform: capitalize;
  font-size: 1rem;
  padding: 0;
} */

#GoogleModalDialog  .MuiPaper-root.MuiDialog-paper {
  align-items:center;
}

#form-dialog-title {
  text-align: center;
}

#GoogleModalDialog  .MuiPaper-root.MuiDialog-paper .MuiTypography-colorTextSecondar {
  font-size:0.8rem;
}

#GoogleModalDialog .MuiDialogTitle-root {
  padding-bottom: 0px;
}
#GoogleModalDialog .MuiDialogContent-root {
  text-align:center;
  color: #fff;
}
#GoogleModalDialog .MuiInput-underline:before {border-color:white;}
#GoogleModalDialog .MuiInput-underline:after {
  border-color:white;
}

label#EmailField-label {color:#fff; z-index:2; font-size:0.7rem; text-align:center;}
/* #GoogleModalDialog .MuiFormControl-root {width:80%;} */
.cm-radio-buttons {
  display: flex;
  justify-content: space-around;
}
.cm-radio-buttons .MuiFormGroup-root {flex-direction:row;}
.cm-radio-buttons legend.MuiFormLabel-root {color:#fff; text-align:left; font-size:0.7rem;}
.cm-radio-buttons .MuiRadio-root {color:#fff;}
.cm-radio-buttons .MuiRadio-colorSecondary.Mui-checked {color:rgb(174, 232, 80);}

.cm-radio-buttons .MuiInputBase-input {color:white !important;}
.MuiSlider-root {
  color: #AEE850 !important;
  max-width: 90%;
  margin-top: 16px;
}
p#discrete-slider-custom {text-align:left; font-size:0.7rem;}
.MuiSlider-markLabel {font-size:0.7rem;}
.MuiSlider-markLabelActive {font-size:0.7rem;}


input#GoogleEmail {
  text-align:center; 
}  

.GoogleAssistantDialog_InputContainer {
  max-width: 300px;
  margin: 0 auto;
}

#GoogleModalDialog .MuiDialogActions-spacing {margin-bottom: 16px;}

.unlink {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

#GoogleModalDialog .MuiInput-underline:after, #GoogleModalDialog .MuiInput-underline:before {display:none;}

@media only screen and (max-width: 1023px) {

  .container-global-dashboard {
    height: calc(1vw * 1000);
  }

}

:root {
    --size: 240px;
    --bar-size: 8px;
    --animation-duration: 1000ms;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
  transform: translate(-50%,-50%);
}

.spinner-item {
    position: absolute;
    width: var(--item-size);
    height: var(--item-size);
    top: calc(50% - var(--item-size) / 2 - var(--bar-size));
    left: calc(50% - var(--item-size) / 2  - var(--bar-size));
    border: var(--bar-size) solid transparent;
    border-left: var(--bar-size) solid var(--clr-spinner);
    border-right: var(--bar-size) solid var(--clr-spinner);
    border-radius: 50%;
    animation: spin var(--item-animation-duration) linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.spinner-item:nth-of-type(1) {
    --item-animation-duration: calc(var(--animation-duration) * 2);
    --item-size: var(--size);
    --clr-spinner: #007680; /* rgb(174, 232, 80); */
    border-top: var(--bar-size) solid #007680; /* rgb(174, 232, 80); */
}

.spinner-item:nth-of-type(2) {
    --item-animation-duration: calc(var(--animation-duration) * 1.5);
    --item-size: calc(var(--size) - 4 * var(--bar-size));
    --clr-spinner: #A0DCFF; /* rgb(155, 104, 255); */
    border-bottom: var(--bar-size) solid #A0DCFF; /* rgb(155, 104, 255); */
}

.spinner-item:nth-of-type(3) {
    --item-animation-duration: var(--animation-duration);
    --item-size: calc(var(--size) - 8 * var(--bar-size));
    --clr-spinner: #E3E48D; /* rgb(64, 163, 255); */
    border-top: var(--bar-size) solid #E3E48D; /* rgb(64, 163, 255); */
}

.spinner-text {
  --item-size: calc(var(--size) - 12 * var(--bar-size));
  position: absolute;
  width: var(--item-size);
  height: var(--item-size);
  top: calc(50% - var(--item-size) / 2 - var(--bar-size));
  left: calc(50% - var(--item-size) / 2  - var(--bar-size));
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center; 
  align-items: center;
  text-align: center;
}

.ahead {
  color: #83BC25!important;
  transform: rotate(180deg);
}

.behind {
  color: #DA291C!important;
}

.background {
  width: auto;
  position: relative;
  height: 275px;
  z-index: 100;
}
/* Start custom MUI styles */
.MuiPaper-root.MuiDialog-paper.trophy-dialog {
  border-radius: 50%;
  width: 344px;
  height: 344px;
  margin: 0px;
}

.trophy-div {
  font-weight:  300;
  font-style: normal;
  line-height: 25px;
  text-align: center;
  padding-top: 60px;
}

.trophy-div .last-line {
  padding-bottom: 10px;
}

.large-text {
  font-size: 30px;
  line-height: 60px;
  font-weight:  bold;
}
.css-o69gx8-MuiCardMedia-root {
  display: block;
  -webkit-background-size: unset!important;
  background-size: unset!important;
  background-repeat: unset!important;
  -webkit-background-position: unset!important;
  background-position: unset!important;
  width: unset!important;
  object-fit: unset!important;
}

/* Start bar styles */
.bar-background {
  position: relative;
  height: 80px;
  margin-bottom: -40px;
  left: 50%;
  transform: translate(calc(-50%), 0px);
  z-index: 100;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.bar-label-legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  min-width: max-content;
  line-height: 1.8;
  align-items: flex-end;
}

.bar-label-legend-left {
  display: flex;
  gap: 4px;
  align-items: center;
}

.bar-label-legend-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  min-width: max-content;
  line-height: 1.8;
  align-items: flex-end;
  text-align: right;
}

.bar-width {
  width: calc(100% - 223px);
}
.hbar-width {
  width: calc(100% - 135px);
}

.bar-label-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  font-weight: 400;
  color: rgb(64, 163, 255);
  min-width: max-content;
  line-height: 1.25;
  align-items: center;
  padding-left: 4px;
  padding-top: 4px;
  margin-left: auto;
}
/* End bar styles */
/* Start donut styles */
.donut-data {
  margin-top: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: -1;
  max-width: 200px;
}
.donut-data .text-center {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
}
.donut-data .text-center p {
  margin: 0;
}
.donut-data .text-center .sub-text {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}
.donut-label-legend {
  display:inline;
  position:absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  z-index: -1;
}

.donut-labels {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: normal;
  margin-top: 8px;
  line-height: 24px;
  width:100%;
  z-index: -1; 
  display:inline-flex;
  justify-content: center;
}

.donut-label-icon {
  position: relative;
  left: -6px;
}

.donut-LYTD-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.5;
  align-items: flex-end;
  left: 49%;
  bottom: 0px;
  transform: translate(-220%, 0px);
}
/* End donut styles */
.ranking-label {
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: rgb(64, 163, 255);
  width: 100%;
  justify-content: center;
  position: relative;
  padding-top: 8px;
}

.ranking-label .pos {
  padding: 0 4px;
}

/* Speech bubble */
.talk-bubble {
margin: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  position: relative;
  width: 90%;
  min-height: 3em;
  height: 100%;
  background-color: lightgray;
  align-items: center;
}

.round{
  border-radius: 0px 30px 30px 30px;
  -webkit-border-radius: 0px 30px 30px 30px;
-moz-border-radius: 0px 30px 30px 30px;
}

.tri-right.left-top:after{
content: ' ';
position: absolute;
width: 0;
height: 0;
  left: -14px;
right: auto;
  top: 0px;
bottom: auto;
border: 22px solid;
border-color: lightgray transparent transparent transparent;
}

.talktext{
  font-size: small;
  font-style: italic;
  color: black;
}

.talktext p{
/* remove webkit p margins */
-webkit-margin-before: 0em;
-webkit-margin-after: 0em;
}

/* header styles start here */
.header-section {
  min-height: 82px;
  background-color: black;
  display: flex;
  align-items: center;
  padding: 0 26px;
  justify-content: space-between;
}

 .header-section .left-section {
  height: 49px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.logo-text-DD {
  margin: 0 0 0 5px;
  color: dodgerblue;
}

.logo-text {
  display: block;
  font-size: 21px;
  font-weight: 700;
  line-height: 29px;
  margin: 0;
  color: gold;
}
/* header styles ends here */

/* Map styles */
.leaflet-container {
  width: 100%;
  height: 45vh;
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.leaflet-tile-pane-dark {
  -webkit-filter: grayscale(100%) brightness(0.6);
  filter: grayscale(100%) brightness(0.6);
}

/* Soccer ball loading animation */
.loader-body {
  width: 100%;
  height: calc(100vh - 56px);
  background: url(https://images.unsplash.com/photo-1449358070958-884ac9579399?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80) no-repeat center 20% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.loader-container {
  position: fixed;
  top: calc(50% + 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  padding: 1rem;
}
.loader-container svg {
  width: 200px;
}
.loader-container svg path {
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  fill: #005bbb;
}
.loader-container svg path.path-7 {
  animation-delay: -1s;
}
.loader-container svg path.path-6 {
  animation-delay: -0.875s;
}
.loader-container svg path.path-5 {
  animation-delay: -0.75s;
}
.loader-container svg path.path-4 {
  animation-delay: -0.625s;
}
.loader-container svg path.path-3 {
  animation-delay: -0.5s;
}
.loader-container svg path.path-2 {
  animation-delay: -0.375s;
}
.loader-container svg path.path-1 {
  animation-delay: -0.25s;
}
.loader-container svg path.path-0 {
  animation-delay: -0.125s;
}

/* Registration Component */
  .MuiStepLabel-iconContainer {
    font-weight: bold;
  }
  .app-name {
    font-weight: bold;
    border-bottom: 2px solid #86BC25;
  }
  .bg-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    background-color: #18181b;
  }

  .bg-overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  .bg-section {
    position: relative;
    animation: slideInFromBottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    flex: 1;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
    opacity: 0;
  }
  .animate-content-card-up {
    animation: slideUpSlightly .5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    opacity: 0;
  }
  
  @keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideUpSlightly {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
    
  }

  .bg-section:first-child {
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
  }

  .bg-section:last-child {
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .bg-section:nth-child(1) {
    background-image: url("https://images.unsplash.com/photo-1526718583451-e88ebf774771?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60");
  }

  .bg-section:nth-child(2) {
    background-image: url("https://images.unsplash.com/photo-1560089000-7433a4ebbd64?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1335&q=80");
    animation-delay: 0.2s;
  }

  .bg-section:nth-child(3) {
    background-image: url("https://images.unsplash.com/photo-1627483298423-03e2e972431c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80");
    animation-delay: 0.4s;
  }

  .bg-section:nth-child(4) {
    background-image: url("https://images.unsplash.com/photo-1629626495452-37bfe96e142b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80");
    animation-delay: 0.6s;
  }

  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    animation-name: slide-in;
    animation-duration: 0.5s;
    animation-delay: 0.8s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translate(-50%, 100%);
    }

    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
/* End Registration Component */

@keyframes pulse {
  0% {
    opacity: 0.65;
  }
  30% {
    opacity: 0.8;
    fill: #ffd500;
  }
  100% {
    opacity: 0.65;
    fill: #005bbb;
  }
}
/* End soccer ball loading animation */